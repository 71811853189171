import { useContext, useState } from 'react';
import AuthContext from '../../contexts/authContext';
import { updateAPI } from '../../nodeApi/cruds';
import Button from '../bootstrap/Button';
import FormGroup from '../bootstrap/forms/FormGroup';
import Label from '../bootstrap/forms/Label';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import ErrorPopup from '../extras/ErrorPopup';
import SearchUsers from '../extras/SearchUsers';
import StatusPopup from '../PlanningCalendar/StatusPopup';


export default function EmployeePopup({ setOpenPopup, handleUpdate = () => { }, data, id }) {
	const { userData } = useContext(AuthContext);
	const [result, setResult] = useState(data || []);
	const [error, setError] = useState();
	const [success, setSuccess] = useState();

	const handleSave = async () => {
		!!id && await updateAPI(userData?.accessToken, "stock_warehouse", id, { employees: result?.map(i => i?._id) })
		handleUpdate(result);
		setOpenPopup(false);
	};

	return (
		<Modal isOpen={true} setIsOpen={() => { }} size={'md'} classNameMd='nv-3'>
			<ModalHeader setIsOpen={() => setOpenPopup(false)}>
				<ModalTitle>Sélectionnez les employés</ModalTitle>
			</ModalHeader>
			<ModalBody className='row'>
				<FormGroup className='mb-3'>
					<Label>Employé</Label>
					<SearchUsers handleSelect={(item) => { setResult((prev) => ([...prev, item])) }} />
				</FormGroup>
				{result?.length > 0 && (
					<div className='row g-3 mt-3'>
						{/* <div className='col-12 text-center'>
									<h2>Voitures</h2>
								</div> */}
						<table className='table table-modern table-hover'>
							<thead>
								<tr>
									<th>Nom</th>
									<th>Role</th>
									<th></th>
								</tr>
							</thead>

							<tbody>
								{result?.map((item, idx) => {
									return (
										<tr key={idx}>
											<td>
												<div>
													{item?.name}
												</div>
											</td>

											<td>
												{item?.function}
											</td>
											<td>
												<Button
													color='primary'
													isLight
													icon='DeleteOutline'
													onClick={() => {
														setResult(
															result?.filter(
																(i) =>
																	item?._id != i?._id,
															),
														);
													}}></Button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}
			</ModalBody>
			<ModalFooter>
				<Button
					color='primary'
					onClick={handleSave}
				>
					Sauvegarder
				</Button>
				<Button
					isLight
					color='primary'
					onClick={() => setOpenPopup(false)}
				>
					Annuler
				</Button>
			</ModalFooter>
			{!!error && (
				<ErrorPopup
					error={error}
					setOpen={setError}
				/>
			)}
			{!!success && (
				<StatusPopup
					icon='Verified'
					color='success'
					title={'Super!'}
					label={success}
					setOpen={setSuccess}
				/>
			)}
		</Modal>
	);
}
