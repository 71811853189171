import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../contexts/authContext';
import { searchAPI } from '../../nodeApi/cruds';
import Spinner from '../bootstrap/Spinner';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import useOutsideClick from '../customComponents/SearchAutoComplete/useOutsideClick';
import Icon from '../icon/Icon';
import ErrorPopup from './ErrorPopup';

export default function SearchUsers({ handleSelect }) {
    const [membre, setMembre] = useState("")
    const [openList, setOpenList] = useState(false);
    const { userData } = useContext(AuthContext)
    const [searchIsLoading, setSearchIsLoading] = useState(false)
    const [filterResult, setfilterResult] = useState([]);
    const [error, setError] = useState(false)

    const handleClickOutside = () => {
        setOpenList(false);
    };
    const ref = useOutsideClick(handleClickOutside);
    const handelSelect = (item) => {
        setOpenList(false);
        setMembre("")
        handleSelect(item)

        setfilterResult([]);
    };
    const handleChangeMembre = async (e, signal) => {
        // setMembre(e)
        setOpenList(true);
        if (e == '') {
            setfilterResult([]);
            setOpenList(false);
        } else {
            setSearchIsLoading(true);
            await searchAPI(userData?.accessToken, 'user', { userName: { $regex: userData?.company?.userName + "_" + e, $options: 'i' } }, 0, 0, (result) => {
                console.log("result", result)
                setfilterResult(result);
                setSearchIsLoading(false);
            },
                (error) => {
                    !(error?.code == 'ERR_CANCELED') && setError(error);
                    setSearchIsLoading(false);
                },

                signal,);
        }
    };

    // const getEmployes = async () => {
    //     const res = await getCustomizedAPI(userData?.accessToken, "employee", "/employees")
    //     setfilterResult(res?.employees || [])
    //     setOpenList(true);
    // }

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;
        handleChangeMembre(membre, signal);
        // getEmployes()

        return () => {
            abortController.abort();
        };
    }, [membre]);

    return (<>
        <div ref={ref} className='row justify-content-end'>
            <FormGroup className='autocomplete' >
                <div className='d-flex align-items-center bg-l10-brand-two rounded-2'>
                    <label className='border-0 ms-2 me-0' htmlFor='searchInput'>
                        <Icon icon='Search' size='2x' color='primary' />
                    </label>

                    <Input
                        // ref={inputRef}
                        onChange={(e) => setMembre(e?.target?.value)}
                        //onFocus={handleChangeClient}
                        value={membre}
                        placeholder='Rechercher...'
                        type='text'
                        autoComplete='off'
                        className='border-0 bg-transparent'
                    />
                </div>

                {openList && (
                    <div
                        id='myInputautocomplete-list'
                        className='autocomplete-items position-absolute'>
                        <div className='body'>
                            {searchIsLoading ? (
                                <div>
                                    <Spinner isSmall isGrow />{' '}
                                    <Spinner isSmall isGrow />{' '}
                                    <Spinner isSmall isGrow />
                                </div>
                            ) : (
                                !!filterResult?.length &&
                                filterResult?.map((item) => (
                                    <div
                                        key={item?.id}
                                        onClick={() => {
                                            handelSelect(item);
                                        }}>
                                        <Icon
                                            color={'primary'}
                                            isLight
                                            icon={'Person'}
                                            size='lg'
                                            className='me-2'
                                        />
                                        {item?.userName?.split('_')[1]}

                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                )}
            </FormGroup>
        </div>
        {!!error && (
            <ErrorPopup
                error={error}
                setOpen={setError}
            />
        )}
    </>
    )
}
