import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import AuthContext from '../../../../contexts/authContext';
import { createAPI } from '../../../../nodeApi/cruds';
import Button from '../../../bootstrap/Button';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../bootstrap/Card';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../bootstrap/Modal';
import Spinner from '../../../bootstrap/Spinner';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';
import Textarea from '../../../bootstrap/forms/Textarea';
import ErrorPopup from '../../../extras/ErrorPopup';
import showNotification from '../../../extras/showNotification';
import Icon from '../../../icon/Icon';

const AddProductPopUp = ({ setCreateProductModalStatus, handleSelectedProduct, consommable = false }) => {
	const { userData } = useContext(AuthContext);
	const [spinner, setSpinner] = useState(false)
	const [error, setError] = useState(false)

	const formik = useFormik({
		initialValues: {
			name: '',
			type: consommable ? "consommable" : 'stockable',
			produce: true,
			prix_achat: 0,
			prix_vente: 0,
			desc: ''
		},
		validate: (values) => {
			const errors = {};
			if (!values.name) {
				errors.name = 'Requis';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			handelCreateProduct(values);
		},
	});
	const handelCreateProduct = async (values) => {
		setSpinner(true)
		try {
			const data = await createAPI(userData?.accessToken, 'product', values);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>Création d'un nouveau produit</span>
				</span>,
				'Le produit a été créé avec succès',
			);
			handleSelectedProduct(data);
			setCreateProductModalStatus(false);
		} catch (error) {
			setError(error);
		} finally {
			setSpinner(false)
		}
	};

	return (
		<Modal setIsOpen={() => { }} isOpen={true} classNameMd='nv-3'>
			<ModalHeader
				className='px-4 pb-0'
				setIsOpen={() => {
					setCreateProductModalStatus(false);
				}}>

			</ModalHeader>
			<ModalBody className='pt-0'>
				<Card shadow='none'>
					<CardHeader className='pt-0'>
						<CardLabel icon='ManageAccounts' iconColor='primary'>
							<CardTitle>Créer un nouveau produit</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody className='row g-4'>
						<FormGroup className='col-12' id='name' label='Nom de produit'>
							<Input
								id='name'
								onChange={formik.handleChange}
								value={formik?.values?.name}
								name='name'
								invalidFeedback={
									formik.errors.name
								}
								// onBlur={formik.handleBlur}
								isValid={formik.isValid}
								isTouched={formik.touched.name}
							/>
						</FormGroup>
						<FormGroup className='col-12' id='desc' label='Description'>
							<Textarea
								onChange={formik.handleChange}
								value={formik.values.desc}
								name='desc'
								type='text'
								autoComplete='off'
							/>
						</FormGroup>
						<FormGroup className='col-12' id='ref_interne' label='Réference'>
							<Input
								onChange={formik.handleChange}
								value={formik.values.ref_interne}
								name='ref_interne'
								type='text'
								autoComplete='off'
							/>
						</FormGroup>

						<FormGroup className='col-6' id='prix_achat' label="Prix d'achat">
							<Input
								onChange={formik.handleChange}
								value={formik.values.prix_achat}
								name='prix_achat'
								type='number'
								placeholder='0'
							/>
						</FormGroup>
						<FormGroup className='col-6' id='prix_vente' label='prix de Vente'>
							<Input
								onChange={formik.handleChange}
								value={formik.values.prix_vente}
								name='prix_vente'
								type='number'
							/>
						</FormGroup>


					</CardBody>
				</Card>
			</ModalBody>
			<ModalFooter className='px-4 pb-4'>
				<Button
					color='primary'
					className='w-100'
					type='submit'
					isDisable={spinner}
					onClick={formik.handleSubmit}>
					{spinner && <Spinner isSmall inButton />}
					{spinner ? 'En cours' : 'Créer'}

				</Button>
			</ModalFooter>
			{!!error && (
				<ErrorPopup
					error={error}
					setOpen={setError}
				/>
			)}
		</Modal >
	);
};

export default AddProductPopUp;
