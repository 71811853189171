import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import AuthContext from '../../contexts/authContext';
import { createAPI, searchAPI, updateAPI } from '../../nodeApi/cruds';
import StatusPopup from '../PlanningCalendar/StatusPopup';
import Button from '../bootstrap/Button';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';
import Spinner from '../bootstrap/Spinner';
import Checks from '../bootstrap/forms/Checks';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Label from '../bootstrap/forms/Label';
import Select from '../bootstrap/forms/Select';
import useOutsideClick from '../customComponents/SearchAutoComplete/useOutsideClick';
import ErrorPopup from '../extras/ErrorPopup';
import Icon from '../icon/Icon';
import HistoriqueCmdPopup from './HistoriqueCmdPopup';

const SearchClient = ({
	data,
	setCreateClientModalStatus,
	handelSelect,
	onChange,
	curentInput,
	handleUpdate,
	newClient = false,
}) => {
	const [resultSearch, setResultSearch] = useState([]);
	const [selectItem, setSelectItem] = useState();
	const { userData } = useContext(AuthContext);
	const [spinner, setSpinner] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [openList, setOpenList] = useState(false);
	const [clientIsLoading, setClientIsLoading] = useState(false);
	const [clientData, setClientData] = useState(data);
	const [filterResult, setfilterResult] = useState({});
	const inputRef = useRef(null);
	const [historiqueCmd, setHistoriqueCmd] = useState(false)
	const [clientValues, setClientValues] = useState({
		name: data?.name || '',
		fullName: data?.name || '',
		phone: data?.phone || '',
		email: data?.email || '',
		mobile: data?.mobile || '',
		adrsFact: (data?.street || '') + ' ' + (data?.zip || '') + ' ' + (data?.city || ''),
		adrsLivr: '',
		street: data?.street || '',
		city: data?.city || '',
		zip: data?.zip || '',
		x_code: data?.x_code || '',
		function: data?.function || 'client',
		partner_delivery: data?.partner_delivery || '',
		ville: ''
	});
	const [resultSearchLivraison, setResultSearchLivraison] = useState([]);

	const handleClickOutside = () => {
		setOpenList(false);
	};
	const ref = useOutsideClick(handleClickOutside);

	const createClient = async (values) => {
		setSpinner(true);
		setSuccess(false);
		setError(false);
		if (formik?.values?.phone?.length !== 8) {
			setError("Le numéro de téléphone doit être à 8 chiffres !!")
			setSpinner(false);
		} else {
			const newUser = {
				name: values?.fullName,
				phone: values?.phone || '',
				mobile: values?.mobile || '',
				email: values?.email || '',
				city: values?.city || '',
				zip: values?.zip || '',
				street: values?.street || '',
				street2: values?.street2 || '',
				// places: selectItem?.url,
				// country_id: 75,
				x_code: values?.x_code || '',
				ville: values?.ville,
				partner_delivery: values?.partner_delivery,
				function: values?.function

			};

			const result = await createAPI(userData?.accessToken, "customer", newUser);
			handelSelect({
				selected: { client: result },
				value: { client: result?.name },

			});
			setCreateClientModalStatus(false);
			setSpinner(false);
			setSuccess('Contact est créer avec success.');
			setError(false);


		}

	};

	const formik = useFormik({
		initialValues: {
			name: data?.name || '',
			fullName: data?.name || '',
			phone: data?.phone || '',
			email: data?.email || '',
			mobile: data?.mobile || '',
			adrsFact: (data?.street || '') + ' ' + (data?.zip || '') + ' ' + (data?.city || ''),
			adrsLivr: '',
			street: data?.street || '',
			city: data?.city || '',
			zip: data?.zip || '',
			x_code: data?.x_code || '',
			function: data?.function || 'client',
			partner_delivery: data?.partner_delivery || '',
			ville: data?.ville

		},
		validate: (values) => {
			const errors = {};

			// if (!values.fullName) {
			// 	errors.fullName = 'Obligatoire !';
			// }
			// if (!!values.email) {
			// 	if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			// 		errors.email = 'Adresse e-mail invalide';
			// 	}
			// }

			// if (!values.phone) {
			// 	errors.phone = 'Obligatoire !';
			// }
			// if (!values.adrsFact) {
			// 	errors.adrsFact = 'Obligatoire !';
			// }
			// if (!values.street) {
			// 	errors.street = 'Obligatoire !';
			// }
			// if (!values.zip) {
			// 	errors.zip = 'Obligatoire !';
			// }
			// if (!values.city) {
			// 	errors.city = 'Obligatoire !';
			// }

			return errors;
		},
		onSubmit: (values) => {
			createClient(values);
		},
	});



	const handelSelectClient = (item) => {
		setOpenList(false);

		// setSelectedClient(item);
		formik?.setFieldValue('name', item?.name);
		formik?.setFieldValue('fullName', item?.name);
		formik?.setFieldValue('email', item?.email);
		formik?.setFieldValue('phone', item?.phone);
		formik?.setFieldValue('mobile', item?.mobile);
		formik?.setFieldValue('x_code', item?.x_code);
		formik?.setFieldValue('partner_delivery', item?.partner_delivery);
		formik?.setFieldValue('ville', item?.ville);
		formik?.setFieldValue('function', item?.function || 'client');
		formik.setFieldValue(
			'adrsFact',
			item?.street +
			' ' +
			item?.zip +
			' ' +
			item?.city +
			' ' +
			item?.country_id?.display_name,
		);

		formik.setFieldValue('zip', item?.zip);
		formik.setFieldValue('city', item?.city);
		formik.setFieldValue('street', item?.street);
		setfilterResult([]);
		setClientData(item);
		setClientValues({
			name: item.name,
			fullName: item.name,
			email: item?.email,
			phone: item.phone,
			mobile: item.mobile,
			x_code: item?.x_code,
			adrsFact: (data?.street || '') + ' ' + (data?.zip || '') + ' ' + (data?.city || ''),
			zip: item?.zip,
			city: item?.city,
			ville: item?.ville,
			function: item?.function || 'client',
			street: item?.street,
			partner_delivery: item?.partner_delivery,
			adrsFact: item?.street +
				' ' +
				item?.zip +
				' ' +
				item?.city +
				' ' +
				item?.country_id?.display_name,

		});
		console.log("item?.id", item?._id)
		setHistoriqueCmd(item?._id)
	};

	const handleChangeClient = async (e, signal) => {
		setOpenList(true);
		setClientIsLoading(true);

		const filter = {
			"$or": [
				{ "name": { "$regex": `${e}`, "$options": "i" } },
				{ "email": { "$regex": `${e}`, "$options": "i" } },
				{ "phone": { "$regex": `${e}`, "$options": "i" } },
				{ "ville": { "$regex": `${e}`, "$options": "i" } }
			]
		}
		const result = await searchAPI(userData?.accessToken, "customer", filter, 1, 10, () => { }, () => { }, signal);
		const customers = result?.elements || [];
		setfilterResult(customers);
		setClientIsLoading(false);


	};


	const handleUpdateSubmit = () => {
		setSpinner(true);
		setSuccess(false);
		setError(false);
		if (formik?.values?.phone?.length !== 8) {
			setError("Le numéro de téléphone doit être à 8 chiffres !!")
			setSpinner(false);
		} else {
			if (
				!!Object.keys(clientValues).some((key) => clientValues[key] !== formik.values[key])
			) {

				const newUser = {
					id: clientData?._id,
					name: formik.values.fullName,
					phone: formik.values.phone || '',
					mobile: formik.values.mobile || '',
					email: formik.values.email || '',
					city: formik.values.city || '',
					zip: formik.values.zip || '',
					street: formik.values.street || '',
					x_code: formik.values.x_code || '',
					partner_delivery: formik.values?.partner_delivery || '',
					odooID: clientData?.odooID,
					ville: formik.values?.ville,
					function: formik.values?.function,
					partner_delivery: formik?.values?.partner_delivery

				};
				updateAPI(
					userData?.accessToken,
					'customer',
					clientData?._id || clientData?.id,
					newUser,
					() => {
						handelSelect({
							selected: { client: newUser },
							value: { client: newUser?.name },
						});
						!!handleUpdate && handleUpdate(newUser);
						setCreateClientModalStatus(false);
						setSpinner(false);
						setSuccess('Contact est créer avec success.');
						setError(false);
					},
					(error) => {
						setSpinner(false);
						setSuccess(false);
						!(error?.code == 'ERR_CANCELED') && setError(error);
					},
				);
			} else {
				const newUser = {
					id: clientData?._id || clientData?.id,
					name: formik.values.fullName,
					phone: formik.values.phone || '',
					mobile: formik.values.mobile || '',
					email: formik.values.email || '',
					city: formik.values.city || '',
					zip: formik.values.zip || '',
					street: formik.values.street || '',
					// places: selectItem?.url,
					// country_id: 75,
					x_code: formik.values.x_code || '',
					partner_delivery: formik.values?.partner_delivery || '',
					odooID: clientData?.odooID,
					function: formik.values?.function,
					ville: formik.values?.ville,
					partner_delivery: formik?.values?.partner_delivery
				};
				handelSelect({
					selected: {
						client: newUser,
					},
					value: { client: newUser?.name },
				});
				!!handleUpdate && handleUpdate(newUser);
				setCreateClientModalStatus(false);
				setSpinner(false);
				setSuccess('Contact est créer avec success.');
				setError(false);
			}
		}


	};

	useEffect(() => {
		const abortController = new AbortController();
		const signal = abortController.signal;
		if (!!formik.values.name) {

			handleChangeClient(formik.values.name, signal);
		} else {
			setfilterResult([]);
			formik?.setFieldValue('fullName', '');
			formik?.setFieldValue('mobile', '');
			formik.setFieldValue('adrsFact', '');
			formik.setFieldValue('phone', '');
			formik.setFieldValue('email', '');
			formik?.setFieldValue('x_code', '');
			formik?.setFieldValue('partner_delivery', '');
			formik?.setFieldValue('ville', '');
			formik?.setFieldValue('function', 'client');
			formik?.setFieldValue('city', '');
			setClientData({});
			setHistoriqueCmd(null)
		}
		return () => {
			abortController.abort();
		};

	}, [formik.values.name]);

	console.log("comparaison ", clientValues?.ville, formik.values?.ville)
	return (
		<Modal
			setIsOpen={() => { }}
			isOpen={true}
			isStaticBackdrop
			isScrollable
			classNameMd='nv-3'
			size={'xl'}>
			<ModalHeader setIsOpen={setCreateClientModalStatus} className='pb-0'></ModalHeader>

			<ModalBody className='pt-0'>
				<Card shadow='none'>
					<CardHeader className='pt-0'>
						{!!newClient ? (
							<CardLabel icon='PersonAddAlt' iconColor='primary'>
								<CardTitle>Nouveau Contact</CardTitle>
							</CardLabel>
						) : (
							<CardLabel icon='ManageAccounts' iconColor='primary'>
								<CardTitle>Contact Client</CardTitle>
							</CardLabel>
						)}

						{!newClient && (
							<CardActions>
								<div ref={ref} className='row justify-content-end'>
									<FormGroup
										className='autocomplete'
									//id='name'
									>
										<div className='d-flex align-items-center bg-l10-brand-two rounded-2'>
											<label
												className='border-0 ms-2 me-0'
												htmlFor='searchInput'>
												<Icon icon='Search' size='2x' color='primary' />
											</label>

											<Input
												ref={inputRef}
												onChange={(e) => {
													formik?.setFieldValue('name', e?.target?.value);
												}}
												value={formik.values.name}
												placeholder='Rechercher...'
												type='text'
												autoComplete='off'
												className='border-0 bg-transparent'
											/>
										</div>

										{openList && (
											<div
												id='myInputautocomplete-list'
												className='autocomplete-items position-absolute'>
												<div className='body'>
													{clientIsLoading ? (
														<div>
															<Spinner isSmall isGrow />{' '}
															<Spinner isSmall isGrow />{' '}
															<Spinner isSmall isGrow />
														</div>
													) : (
														!!filterResult?.length &&
														filterResult?.map((item) => (
															<div
																key={item?._id}
																onClick={() => {
																	handelSelectClient(item);
																}}>
																<Icon
																	color={'primary'}
																	isLight
																	icon={'Person'}
																	size='lg'
																/>
																{item?.name}{' '}
																<Icon
																	color={'primary'}
																	isLight
																	icon={'call'}
																	size='lg'
																/>
																{item?.phone}
																<Icon
																	color={'primary'}
																	isLight
																	icon={'Place'}
																	size='lg'
																/>
																{item?.ville +
																	' ' +
																	item?.city +
																	' ' +
																	item?.street}
															</div>
														))
													)}
												</div>
											</div>
										)}
									</FormGroup>
								</div>
							</CardActions>
						)}
					</CardHeader>
					<CardBody>
						<div className='row g-4'>
							<FormGroup className='col-lg-6 ' id='fullName' label='Nom Complet'>
								<Input
									onChange={formik.handleChange}
									value={formik.values.fullName}
									onBlur={formik.handleBlur}
									isValid={formik.isValid}
									isTouched={formik.touched.fullName}
									invalidFeedback={formik.errors.fullName}
									name='fullName'
									type='name'
									autoComplete='off'
								/>
							</FormGroup>
							<FormGroup className='col-lg-6 ' id='email' label='Adresse e-mail'>
								<Input
									onChange={formik.handleChange}
									value={formik.values.email}
									onBlur={formik.handleBlur}
									isValid={formik.isValid}
									isTouched={formik.touched.email}
									invalidFeedback={formik.errors.email}
									name='email'
									type='email'
									autoComplete='off'
								/>
							</FormGroup>

							<FormGroup className='col-lg-6' id='phone' label='Tél'>
								<Input
									onChange={formik.handleChange}
									value={formik.values.phone}
									isTouched={formik.touched.phone}
									isValid={formik.isValid}
									onBlur={formik.handleBlur}
									invalidFeedback={formik.errors.phone}
									// validFeedback='Bien!'
									name='phone'
									type='tel'
								/>
							</FormGroup>
							<FormGroup className='col-lg-6' id='mobile' label='Mobile'>
								<Input
									onChange={formik.handleChange}
									value={formik.values.mobile}
									name='mobile'
									type='tel'
								/>
							</FormGroup>

							<div className='col-md-6'>
								<Card className='rounded-1 mb-0'>
									<CardHeader>
										<CardLabel icon='ReceiptLong'>
											<CardTitle>Adresse de facturation</CardTitle>
										</CardLabel>
									</CardHeader>
									<CardBody>
										<div className='row g-3'>


											<FormGroup id='ville' className='col-md-12' label='Gouvernorat'>
												<Select
													name='ville'
													value={formik?.values?.ville}
													onChange={formik?.handleChange}
													onBlur={formik?.handleBlur}
													isTouched={formik?.touched?.ville}
													isValid={formik?.isValid}
													invalidFeedback={formik?.errors?.ville}
												>
													<option value=''>Sélectionnez un gouvernorat</option>
													<option value='Ariana'>Ariana</option>
													<option value='Béja'>Béja</option>
													<option value='Ben Arous'>Ben Arous</option>
													<option value='Bizerte'>Bizerte</option>
													<option value='Gabès'>Gabès</option>
													<option value='Gafsa'>Gafsa</option>
													<option value='Jendouba'>Jendouba</option>
													<option value='Kairouan'>Kairouan</option>
													<option value='Kasserine'>Kasserine</option>
													<option value='Kébili'>Kébili</option>
													<option value='Le Kef'>Le Kef</option>
													<option value='Mahdia'>Mahdia</option>
													<option value='La Manouba'>La Manouba</option>
													<option value='Médenine'>Médenine</option>
													<option value='Monastir'>Monastir</option>
													<option value='Nabeul'>Nabeul</option>
													<option value='Sfax'>Sfax</option>
													<option value='Sidi Bouzid'>Sidi Bouzid</option>
													<option value='Siliana'>Siliana</option>
													<option value='Sousse'>Sousse</option>
													<option value='Tataouine'>Tataouine</option>
													<option value='Tozeur'>Tozeur</option>
													<option value='Tunis'>Tunis</option>
													<option value='Zaghouan'>Zaghouan</option>
												</Select>
											</FormGroup>
											<FormGroup id='city' className='col-md-12' label='Ville'>
												<Input
													type='text'
													name='city'
													value={formik?.values?.city}
													onChange={formik?.handleChange}
													isTouched={formik.touched.city}
													isValid={formik.isValid}
													onBlur={formik.handleBlur}
													invalidFeedback={formik.errors.city}
													validFeedback='Bien!'
												/>
											</FormGroup><FormGroup id='street' className='col-md-12' label='Adresse complète'>
												<Input
													type='text'
													name='street'
													value={formik?.values?.street}
													onChange={formik?.handleChange}
													isTouched={formik.touched.street}
													isValid={formik.isValid}
													onBlur={formik.handleBlur}
													invalidFeedback={formik.errors.street}
													validFeedback='Bien!'
												/>
											</FormGroup>
											{/* <SearchAutoComplete
												label='Adresse Facturation'
												className='col-12'
												value={formik.values.adrsFact}
												onChange={handleChange}
												options={resultSearch}
												setSelectItem={handelSelectItem}
												isTouched={formik.touched.adrsFact}
												isValid={formik.isValid}
												onBlur={formik.handleBlur}
												invalidFeedback={formik.errors.adrsFact}
											/> */}
											{/* <FormGroup className='col-md-4' id='street' label='Rue'>
												<Input
													type='text'
													name='street'
													value={formik?.values?.street}
													onChange={formik?.handleChange}
													isTouched={formik.touched.street}
													isValid={formik.isValid}
													onBlur={formik.handleBlur}
													invalidFeedback={formik.errors.street}
													validFeedback='Bien!'
												/>
											</FormGroup> */}

											{/* <FormGroup id='zip' className='col-md-4' label='Code postal'>
												<Input
													type='number'
													name='zip'
													value={formik?.values?.zip}
													onChange={formik?.handleChange}
													isTouched={formik.touched.zip}
													isValid={formik.isValid}
													onBlur={formik.handleBlur}
													invalidFeedback={formik.errors.zip}
													validFeedback='Bien!'
												/>
											</FormGroup> */}

											{/* <FormGroup id='city' className='col-md-4' label='Ville'>
												<Input
													type='text'
													name='city'
													value={formik?.values?.city}
													onChange={formik?.handleChange}
													isTouched={formik.touched.city}
													isValid={formik.isValid}
													onBlur={formik.handleBlur}
													invalidFeedback={formik.errors.city}
													validFeedback='Bien!'
												/>
											</FormGroup> */}

										</div>
									</CardBody>
								</Card>
							</div>


							<div className='col-md-6'>
								<Card stretch className='rounded-1 mb-0'>
									<CardHeader>
										<CardLabel icon='ReceiptLong'>
											<CardTitle>Adresse de livraison</CardTitle>
										</CardLabel>
									</CardHeader>
									<CardBody>
										<div className='row g-3'>
											<FormGroup id='partner_delivery' className='col-md-12' label='Adresse complète'>
												<Input
													type='text'
													name='partner_delivery'
													value={formik?.values?.partner_delivery}
													onChange={formik?.handleChange}
													isTouched={formik.touched.partner_delivery}
													isValid={formik.isValid}
													onBlur={formik.handleBlur}
													invalidFeedback={formik.errors.partner_delivery}
													validFeedback='Bien!'
												/>
											</FormGroup>

										</div>

									</CardBody>
								</Card>
							</div>
							<FormGroup id='function' >
								<div className="d-flex">
									<Checks
										type='checkbox'
										name='function'
										id='function'
										value={formik?.values?.function}
										onChange={() => formik.setFieldValue("function", formik?.values?.function == "fournisseur" ? "client" : "fournisseur")}
										checked={formik?.values?.function == "fournisseur"}
									/>
									<Label>Fournisseur</Label>
								</div>

							</FormGroup>
							{!!historiqueCmd && <HistoriqueCmdPopup clientId={historiqueCmd} />}
						</div>
					</CardBody>
				</Card>
			</ModalBody>


			<ModalFooter className='px-4 pb-4'>
				{(!!clientData?.id || !!clientData?._id) ? (
					<Button
						color='primary'
						isDisable={spinner}
						onClick={handleUpdateSubmit}>
						{spinner && <Spinner isSmall inButton />}
						{spinner
							? 'En cours'
							: `${!!Object.keys(clientValues).some(
								(key) => clientValues[key] !== formik.values[key],
							)
								? 'Sauvegarder'
								: 'Sélectionner'
							}`}
					</Button>
				) : (
					<Button
						color='primary'
						type='submit'
						isDisable={spinner}
						onClick={formik.handleSubmit}>
						{spinner && <Spinner isSmall inButton />}
						{spinner ? 'En cours' : 'Créer'}
					</Button>
				)}
			</ModalFooter>

			{!!success && (
				<StatusPopup
					icon='Verified'
					color='success'
					title={'Super!'}
					label={success}
					setOpen={setSuccess}
				/>
			)}
			{!!error && <ErrorPopup error={error} setOpen={setError} />}
			{/* {!!historiqueCmd && <HistoriqueCmdPopup clientId={historiqueCmd} setOpen={setHistoriqueCmd} />} */}
		</Modal>
	);
};

export default SearchClient;
