import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import AuthContext from '../../contexts/authContext';
import Button from '../bootstrap/Button';
import Spinner from '../bootstrap/Spinner';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import useOutsideClick from '../customComponents/SearchAutoComplete/useOutsideClick';
import ErrorPopup from '../extras/ErrorPopup';
import Icon from '../icon/Icon';

export default function SearchBox({ selectedItem, handelSelect, handleScan, handleSearch, setOpenPop, defaultList = [], multiSelect = false, selectedItems = [], disabled = false, defaultSearch = false, displayedItem = ((item) => item?.name), onTextChange, className }) {
	const { userData } = useContext(AuthContext);
	const [error, setError] = useState(false);
	const [filterResult, setfilterResult] = useState(defaultList);
	const inputRef = useRef(null);
	const [searchIsLoading, setSearchIsLoading] = useState(false);
	const [openListItems, setOpenListItems] = useState(false);

	const handleClickOutside = () => {
		setOpenListItems(false);
	};
	const ref = useOutsideClick(handleClickOutside);
	const formik = useFormik({
		initialValues: {
			name: selectedItem?.name || selectedItem || '',
		},
	});

	const handelChange = (e) => {
		formik?.setFieldValue('name', e?.target?.value);
		onTextChange?.(e?.target?.value);
		//setSearchItem(e.target.value);
		setOpenListItems(true);
	};


	const handelSelectItem = (item) => {
		if (!!multiSelect) {
			if (!selectedItems.includes(item)) {
				handelSelect([...selectedItems, item]);
				// setfilterResult(defaultList?.filter(value => ![...selectedItems, item]?.includes(value)));

			}
			// else {
			// 	setfilterResult(defaultList?.filter(value => !selectedItems?.includes(value)));
			// }
			formik?.setFieldValue('name', "");
		} else {
			defaultSearch ? formik?.setFieldValue('name', "") : formik?.setFieldValue('name', item?.name || item);
			handelSelect(item);
			setfilterResult([]);

		}
		setOpenListItems(false);
		setSearchIsLoading(false);
	};

	const handleRemoveItem = (item) => {
		handelSelect(selectedItems?.filter((i) => i !== item));
	};
	useEffect(() => {
		const abortController = new AbortController();
		const signal = abortController.signal;

		const search = async () => {
			setSearchIsLoading(true);
			setError(false); // Reset error state before search
			try {
				console.log("formik?.values?.name", formik?.values?.name)
				const result = await handleSearch(formik?.values?.name, signal);
				setfilterResult(result);
			} catch (error) {
				setError(error.message); // Set the error message to display
			} finally {
				setSearchIsLoading(false);
			}
		};

		if (!!formik?.values?.name) {
			search();
		} else {
			setfilterResult(defaultList);
			setSearchIsLoading(false);
		}

		return () => {
			abortController.abort();
		};
	}, [formik?.values?.name, defaultList]);

	useEffect(() => {
		if (!!multiSelect && !!defaultList?.length) {
			const newFilterResult = defaultList?.filter(value => !selectedItems?.includes(value));
			if (JSON.stringify(newFilterResult) !== JSON.stringify(filterResult)) {
				setfilterResult(newFilterResult);
			}
		}
	}, [selectedItems]);

	useEffect(() => {
		formik?.setFieldValue('name', selectedItem?.name);
	}, [selectedItem?.name])

	return (
		<>
			<div ref={ref} className={`${className} row`}
			>
				<FormGroup className='autocomplete'>
					{!!multiSelect
						? <div className='p-2 d-flex align-items-center bg-l10-brand-two rounded-2 flex-wrap'>
							{selectedItems?.map((item, index) => (
								<Button key={index}
									isOutline
									color='dark'
									// isLight
									className={"text-nowrap"}
									//icon='Close'
									onClick={() => { handleRemoveItem(item) }}>	{item?.name || item} <Icon icon='Close'></Icon></Button>
							))}
							<Input
								ref={inputRef}
								onChange={handelChange}
								//onFocus={handleChangeClient}
								value={formik?.values?.name}
								placeholder='Rechercher...'
								type='text'
								autoComplete='off'
								className='border-0 bg-transparent'
								onFocus={() => setOpenListItems(true)}
								disabled={disabled}
							/>
						</div>
						: <div className='d-flex align-items-center bg-l10-brand-two rounded-2'>
							<Input
								ref={inputRef}
								onChange={handelChange}
								//onFocus={handleChangeClient}
								value={formik?.values?.name}
								placeholder='Rechercher...'
								type='text'
								autoComplete='off'
								className='border-0 bg-transparent'
								onFocus={() => setOpenListItems(true)}
								disabled={disabled}
								onKeyDown={(event) => {
									console.log("event.key ", event.key);

									if (event.key === 'Enter') {
										if (!!handleScan) {
											handleScan(formik?.values?.name);
											formik?.setFieldValue('name', "");
										}

									}
								}}
							/>
						</div>}


					{openListItems &&
						(searchIsLoading ? (
							<div
								id='myInputautocomplete-list'
								className='autocomplete-items position-absolute'>
								<div className='body'>
									<div>
										<Spinner isSmall isGrow /> <Spinner isSmall isGrow />{' '}
										<Spinner isSmall isGrow />
									</div>
								</div>
							</div>
						) : (
							<div
								id='myInputautocomplete-list'
								className='autocomplete-items position-absolute' style={{ zIndex: 9999 }}>
								<div className='body'>
									{!!filterResult?.length &&
										filterResult?.map((item) => (
											<div
												key={item?.id}
												onClick={() => {
													handelSelectItem(item);
												}}>
												{/* {item?.name || item} */}
												{displayedItem(item)}
											</div>
										))}
									{!!setOpenPop && <div
										className='col-12'
										color='dark'
										isLight
										onClick={() => {
											setOpenPop(true);
											setOpenListItems(false);
											formik?.setFieldValue('name', "");
										}}>
										Crèer et modifier {formik?.values?.name || "..."}
									</div>}

								</div>
							</div>
						))}
				</FormGroup>
			</div>
			{!!error && <ErrorPopup error={error} setOpen={setError} />}

		</>
	);
}
