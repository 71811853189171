import moment from 'moment';
import 'moment/locale/fr';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TypeIntervention from '../../../common/data/PlanningData/type_intervention';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import AuthContext from '../../../contexts/authContext';
import PoseContext from '../../../contexts/poseContext';
import { searchClientPartner } from '../../../odooApi/clientPartner';
import { searchEmployee } from '../../../odooApi/employee';
import searchPlanning, {
	createPlanningSlot,
	sendInterventionMail,
	sendInterventionPoseByMail,
	uploadAttachementsCommercial,
} from '../../../odooApi/planningSlot';
import Button from '../../bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTabItem,
	CardTitle,
} from '../../bootstrap/Card';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Option from '../../bootstrap/Option';
import Spinner from '../../bootstrap/Spinner';
import Select from '../../bootstrap/forms/Select';
import Icon from '../../icon/Icon';
import PosePopup from './PosePopup';

import motifIntervention from '../../../common/data/PlanningData/motif_intervention';
import createNotification from '../../../common/function/createNotification';
import { DevisLinesContextProvider } from '../../../contexts/devisLinesContext';
import { planningMenu } from '../../../gestioneerMenu';
import searchDevis, { createDevis } from '../../../odooApi/devis';
import Attachements from '../../Attachements/Attachements';
import SearchClient from '../../Clients/SearchClient';
import DevisCard from '../../Devis/DevisCard';
import DevisPopUp from '../../Devis/DevisPopUp';
import EmployeePopup from '../../Stock/EmployeePopup';
import Label from '../../bootstrap/forms/Label';
import useOutsideClick from '../../customComponents/SearchAutoComplete/useOutsideClick';
import ErrorPopup from '../../extras/ErrorPopup';
import SearchBoxClient from '../Client/SearchBoxClient';
import PopUpSeachDevisIntervention from '../PopUpSeachDevisIntervention';
import PopUpSeachParentIntervention from '../PopUpSeachParentIntervention';
import StatusPopup from '../StatusPopup';
import VoiturePopup from './VoiturePopup';
//const { setPlanning } = useContext(PlanningContext);

const InterventionCreatePopUp = ({
	setCreateModalStatus,
	newInterventionData,
	getInterventions,
	clientData,
	newData,
	setEditModalStatus,
}) => {
	const { userData } = useContext(AuthContext);
	const [searchModalStatus, setSearchModalStatus] = useState(false);
	const [selectedClient, setSelectedClient] = useState(
		newInterventionData?.client || newData?.clientData || {},
	);
	console.log('newData', !!newData);
	const [selectedEmployee, setSelectedEmployee] = useState({});
	const [filterResult, setfilterResult] = useState({});
	const [parentInterventionsData, setParentInterventionsData] = useState([]);
	const [employeePopup, setEmployeePopup] = useState(false);

	const [formState, setFormState] = useState({
		...(newData || {
			start_datetime: moment(newInterventionData?.start).format('YYYY-MM-DD HH:mm:ss') || '',
			end_datetime: moment(newInterventionData?.end).format('YYYY-MM-DD HH:mm:ss') || '',
			voitures: [newInterventionData?.employee?.voitures[0]],
			devis_liste: [],
		}),
		allowAucunDevis: false,
	});

	const [curentInput, setCurentInput] = useState({ inputName: '', inputLabel: '' });
	const { t } = useTranslation(['translation', 'menu']);

	const [attachmentsCommercial, setAttachmentsCommercial] = useState(
		newData?.attachmentsCommercial || [],
	);

	const [error, setError] = useState();
	const { pose, setPose } = useContext(PoseContext);
	const [posePopup, setposePopup] = useState(!!Object.keys(pose).length);
	const [voiturePopup, setVoiturePopup] = useState(false);

	const handleClickOutside = () => {
		setOpenList(false);
	};
	const ref = useOutsideClick(handleClickOutside);
	const [openList, setOpenList] = useState(false);
	const [devisIsLoading, setDevisIsLoading] = useState(false);
	const [filterDevisResult, setFilterDevisResult] = useState({});
	const [devisInputSearch, setDevisInputSearch] = useState('');
	const [newDevisData, setNewDevisData] = useState({});
	const [devisDatas, setDevisDatas] = useState([]);
	const [editDevisModalStatus, setEditDevisModalStatus] = useState(false);
	const [obligation, setObligation] = useState(false);
	const [isFournisseur, setIsFournisseur] = useState(false);
	const [parentIntervention, setParentIntervention] = useState(false);
	const [parentDevis, setParentDevis] = useState(false);

	moment.locale('fr');
	const [isLoading, setisLoading] = useState(false);

	const handleCreateDevis = () => {
		if (userData && userData?.id && userData?.password) {
			const newDevis = {
				project_title: 'Devis ' + formState?.motif + ' ' + selectedClient?.name,
				partner_id: selectedClient?.id,
				//ref_intervention: formState?.name_seq,
				//planning_id: formState?.id,
				//responsable_intervention: formState?.login_user?.id,
				// company_id: formState?.login_user?.company_id?.id,
				techniciens: formState?.voitures?.map((voiture) =>
					voiture?.conducteur?.map((item) => {
						return item?.id;
					}),
				),
			};
			createDevis(
				userData?.password,
				newDevis,
				(devisData) => {
					handelSelectDevis(devisData);
					setNewDevisData(devisData);
					setEditDevisModalStatus(true);
				},
				(error) => {
					setError(error);
				},
			);
		}
	};


	const handleCreateIntervention = () => {
		setError(false);
		setObligation(false);
		if (userData && userData?.id && userData?.password) {
			const employees = [];
			const users = [28];
			formState?.voitures?.map((voiture) => {
				voiture?.conducteur?.map((technicien) => {
					employees.push(technicien?.id);
					let filter = [['id', '=', technicien?.id]];
					searchEmployee(userData?.password, filter, ({ elements }) => {
						users.push(elements[0]?.user_id);
					});
				});
			});

			const newPlanning = {
				type_int: formState?.type_int || '',
				motif: formState?.motif || '',
				company_id: formState?.voitures[0]?.conducteur[0]?.company_id,
				partner_id: selectedClient?.id || formState?.partner_id,
				start_datetime:
					moment(formState?.start_datetime).format('YYYY-MM-DD HH:mm:ss') || '',
				end_datetime: moment(formState?.end_datetime).format('YYYY-MM-DD HH:mm:ss') || '',
				//employee_id: newInterventionData?.employee?.id,
				//voiture: newInterventionData?.employee?.voitures[0]?.id, // modifier !!!
				voitures: formState?.voitures?.map((v) => v?.id),
				//employees_ids: [selectedEmployee2?.id],
				Remarque: formState?.Remarque || '',
				name: formState?.name || '',
				// employees_ids: [newInterventionData?.employee?.id], // modifier !!!
				employees_ids: employees,
				devis_liste: formState?.devis_liste || [],
				parent_id: formState?.parent_id || false,
				amount_residual: formState?.amount_residual,
			};

			setisLoading(true);
			setError(false);

			createPlanningSlot(
				userData?.password,
				newPlanning,
				(planningData) => {
					!!setEditModalStatus && setEditModalStatus(false);

					if (attachmentsCommercial?.length > 0) {
						uploadAttachementsCommercial(
							userData?.password,
							planningData?.id,
							attachmentsCommercial,
							() => {
								if (planningData?.partner_id?.email) {
									if (planningData?.type_int == 'Pose') {
										sendInterventionPoseByMail(
											userData?.password,
											planningData?.id,
											true,
											() => {
												updatePoseDevis();
												getInterventions();
												setCreateModalStatus(false);
												setisLoading(false);
											},
										);
									} else if (
										planningData?.type_int == 'Intervention' ||
										planningData?.type_int == 'Enlèvement'
									) {
										getInterventions();
										setCreateModalStatus(false);
										setisLoading(false);
									} else {
										sendInterventionMail(
											userData?.password,
											planningData?.id,
											() => {
												setPose({});
												getInterventions();
												setCreateModalStatus(false);
												setisLoading(false);
											},
											(error) => {
												setisLoading(false);
												setError(error);
											},
										);
									}
								} else {
									if (planningData?.type_int == 'Pose') {
										updatePoseDevis();
									}
									setPose({});
									getInterventions();
									setEditModalStatus(false);
									setisLoading(false);
									setError(false);
								}
							},
							(error) => {
								setisLoading(false);

								setError(error);
							},
						);
					} else {
						if (planningData?.partner_id?.email) {
							if (planningData?.type_int == 'Pose') {
								sendInterventionPoseByMail(
									userData?.password,
									planningData?.id,
									true,
									() => {
										updatePoseDevis();
										getInterventions();
										setCreateModalStatus(false);
										setisLoading(false);
										setError(false);
									},
								);
							} else if (
								planningData?.type_int == 'Intervention' ||
								planningData?.type_int == 'Enlèvement'
							) {
								getInterventions();
								setCreateModalStatus(false);
								setisLoading(false);
								setError(false);
							} else {
								sendInterventionMail(
									userData?.password,
									planningData?.id,
									() => {
										setPose({});
										getInterventions();
										setCreateModalStatus(false);
										setisLoading(false);
										setError(false);
									},
									(error) => {
										setisLoading(false);
										setError(error);
									},
								);
							}
						} else {
							if (planningData?.type_int == 'Pose') {
								updatePoseDevis();
							}
							setPose({});
							getInterventions();
							setCreateModalStatus(false);
							setisLoading(false);
							setError(false);
						}
					}

					createNotification({
						recieverMembers: users,
						text: ` 
										Nouvelle intervention N° ${planningData?.name_seq} de ${planningData?.type_int} ${planningData?.motif
							}
									
										Client: ${planningData?.partner_id?.name}
									
									 	Date:${moment(planningData?.start_datetime).format('DD-MM-YYYY')}: ${moment(
								planningData?.start_datetime,
							).format('hh:mm')} - ${moment(planningData?.end_datetime).format('hh:mm')}
										`,
						icon: 'Notifications',
						color: 'info',
						link: planningMenu?.path,
					});
				},

				(error) => {
					setisLoading(false);
					setError(error);
				},
			);
		}
	};

	const handleChange = (e) => {
		setFormState({ ...formState, [e.target.name]: e.target.value });

		if (e.target.name == 'client') {
			setSearchModalStatus(true);
			setCurentInput({ inputName: e.target.name, inputLabel: 'Clients' });

			if (userData && userData?.id && userData?.password) {
				searchClientPartner(
					userData?.password,
					[
						'|',
						['name', 'like', e.target.value],
						'|',
						[
							'name',
							'like',
							e.target.value.charAt(0).toUpperCase() +
							e.target.value.substring(1).toLowerCase(),
						],
						['name', 'like', e.target.value.toUpperCase()],
					],
					({ elements }) => {
						setfilterResult({ [e.target.name]: elements });
					},
					(error) => {
						setError(error);
					},
					1,
					5,
				);
			}
		} else if (e.target.name == 'employee2') {
			setSearchModalStatus(true);
			setCurentInput({ inputName: e.target.name, inputLabel: 'employees' });
			if (userData && userData?.id && userData?.password) {
				searchEmployee(
					userData?.password,
					[
						'&',
						['id', '!=', selectedEmployee?.id],
						'&',
						['job_title', 'in', ['Technicien', 'Chargé affaire']],
						'|',
						['name', 'like', e.target.value],
						'|',
						[
							'name',
							'like',
							e.target.value.charAt(0).toUpperCase() +
							e.target.value.substring(1).toLowerCase(),
						],
						['name', 'like', e.target.value.toUpperCase()],
					],
					({ elements }) => {
						setfilterResult({ [e.target.name]: elements });
					},
					(error) => {
						setError(error);
					},
				);
			}
		} else if (e.target.name == 'voiture') {
			setVoiturePopup(true);
		} else if (e.target.name == 'employee') {
			setSearchModalStatus(true);
			setCurentInput({ inputName: e.target.name, inputLabel: 'employees' });
			if (userData && userData?.id && userData?.password) {
				searchEmployee(
					userData?.password,
					[
						'&',
						['job_title', 'in', ['Technicien', 'Chargé affaire', 'achat']],
						'|',
						['name', 'like', e.target.value],
						'|',
						[
							'name',
							'like',
							e.target.value.charAt(0).toUpperCase() +
							e.target.value.substring(1).toLowerCase(),
						],
						['name', 'like', e.target.value.toUpperCase()],
					],
					({ elements }) => {
						setfilterResult({ [e.target.name]: elements });
					},
					(error) => {
						setError(error);
					},
				);
			}
		} else if (e.target.name == 'type_int') {
			setCurentInput({ inputName: [e.target.name] });
			if (e.target.value == 'Enlèvement') {
				setIsFournisseur(true);
			} else {
				setIsFournisseur(false);
				//setSelectedClient({});
			}
		} else {
			setCurentInput({ inputName: [e.target.name] });
		}
	};
	const deleteImage = (key) => {
		const updatedImages = attachmentsCommercial.filter((item, index) => index != key);
		setAttachmentsCommercial(updatedImages);
	};

	const handelSelect = ({ value, selected }) => {
		if (!!selected?.client) {
			setSelectedClient({ ...selected?.client });
			setFormState({ ...formState, ...value });
		}
		if (!!selected?.employee) {
			setSelectedEmployee({ ...selected?.employee });
			setFormState({ ...formState, ...value });
		}
		if (!!selected?.voiture) {
			setSelectedVoiture({ ...selected?.voiture });
			setFormState({ ...formState, ...value });
		}
		if (!!selected?.employee2) {
			setFormState({ ...formState, ...value });
		}
	};

	const handleDevisChange = (e) => {
		setOpenList(true);
		setDevisInputSearch(e?.target?.value);
	};

	const handelSelectDevis = (item) => {
		setOpenList(false);
		const exist = !!formState?.devis_liste?.find((id) => id == item?.id);
		if (!exist) {
			formState.devis_liste = [...formState?.devis_liste, item?.id];
		}
		setDevisDatas([...devisDatas, item]);
		setFilterDevisResult([]);
	};

	const loadDevis = () => {
		searchDevis(userData?.password, [['id', 'in', formState?.devis_liste]], ({ elements }) => {
			setDevisDatas(elements);
		});
	};

	useEffect(() => {
		const abortController = new AbortController();
		const signal = abortController.signal;
		if (devisInputSearch === '') {
			setFilterDevisResult([]);
		} else {
			setDevisIsLoading(true);
			searchDevis(
				userData?.password,
				[
					'|',

					['name', 'ilike', devisInputSearch],

					'|',
					['nom_client', 'ilike', devisInputSearch],

					'|',
					['phone_client', 'ilike', devisInputSearch],

					['mobile_client', 'ilike', devisInputSearch],
				],
				({ elements }) => {
					setFilterDevisResult(elements);
					setDevisIsLoading(false);
				},
				(error) => {
					//setError(error);
					setDevisIsLoading(true);
				},
				signal,
			);
		}
		return () => {
			abortController.abort();
		};
	}, [devisInputSearch]);

	useEffect(() => {
		formState?.devis_liste?.length > 0 && loadDevis();
	}, [formState?.devis_liste]);

	// useEffect(() => {
	// 	!!!newData &&
	// 		getSingleVoitureId(
	// 			userData?.password,
	// 			newInterventionData?.employee?.voitures[0]?.id,
	// 			(result) => {
	// 				//setVoituresIsLoading(false);
	// 				setFormState({ ...formState, voitures: [result] });
	// 			},
	// 			(error) => {
	// 				setError(error);
	// 			},
	// 		);
	// }, []);

	const validateForm = () => {
		if (!formState?.type_int || !selectedClient?.name || !formState?.motif) {
			setObligation("Remplir type d'intervention, motif et le client !");
			return false;
		} else if (!moment(formState?.start_datetime).isBefore(formState?.end_datetime)) {
			setObligation('Verifier la date svp !');
			return false;
		}
		return true;
	};

	const handlePoseWithoutDevis = () => {
		setisLoading(true);
		searchDevis(
			userData?.password,
			[['partner_id', '=', selectedClient?.id]],
			({ elements }) => {
				setParentInterventionsData(elements);
				setParentDevis(true);
				setisLoading(false);
			},
			(error) => {
				setError(error);
				setisLoading(false);
			},
			false,
		);
	};

	const handlePoseWithoutParentId = () => {
		setisLoading(true);
		searchPlanning(
			userData?.password,
			[['partner_id', '=', selectedClient?.id]],
			({ elements }) => {
				if (!!elements?.length) {
					setParentInterventionsData(elements);
					setParentIntervention(true);
				} else {
					handleCreateIntervention();
				}
				setisLoading(false);
			},
			() => {
				setisLoading(false);
			},
			false,
		);
	};

	const handelSubmit = () => {
		if (!validateForm()) return;
		setisLoading(true);
		if (
			formState?.type_int === 'Pose' &&
			!!!formState?.devis_liste?.length &&
			!formState?.allowAucunDevis
		) {
			handlePoseWithoutDevis();
		} else if (formState?.type_int === 'Pose' && !!!formState?.parent_id) {
			handlePoseWithoutParentId();
		} else {
			handleCreateIntervention();
		}
		setisLoading(false);
	};

	return (
		<>
			<Modal setIsOpen={() => { }} isOpen={true} size='xl' isScrollable classNameMd='nv-2'>
				<ModalHeader className='px-4 pb-0' setIsOpen={setCreateModalStatus}>
					<div className='w-100 text-muted text-center'>
						<ModalTitle className='fw-semibold fs-4'>Nouvelle Intervention</ModalTitle>
					</div>
				</ModalHeader>
				<ModalBody className='px-4 pt-0'>
					<Card hasTab shadow={'none'}>
						<CardTabItem id='tab' title='Détails'>
							<div className='row'>
								<div className='col-xl-6 col-md-12'>
									<Card>
										<CardHeader>
											<CardLabel icon='InfoOutline' iconColor='primary'>
												<CardTitle>Informations d'intervention</CardTitle>
											</CardLabel>
										</CardHeader>
										<CardBody>
											<div className='row g-4'>
												<FormGroup
													label='Type Intervention *'
													className='col-12'
												>
													<Select
														ariaLabel='type_int'
														onChange={handleChange}
														placeholder={
															!!formState?.type_int
																? formState?.type_int
																: '-- aucun --'
														}
														name='type_int'>
														{TypeIntervention?.map((item, idx) => {
															return (
																<Option
																	key={idx}
																	type='radio'
																	name='type_int'
																	id='type_int'
																	value={item?.name}>
																	{item?.name}
																</Option>
															);
														})}
													</Select>
												</FormGroup>

												<FormGroup
													label="Motif d'intervention *"
													className='col-12'>
													<Select
														ariaLabel='motif'
														onChange={handleChange}
														placeholder={
															!!formState?.motif
																? formState?.motif
																: '-- aucun --'
														}
														name='motif'>
														{motifIntervention?.map((item, idx) => {
															return (
																<Option
																	key={idx}
																	type='radio'
																	name='motif'
																	id='motif'
																	value={item?.name}>
																	{item?.name}
																</Option>
															);
														})}
													</Select>
												</FormGroup>

												<div className='row mt-5 d-flex justify-content-between'>
													<div className='col-auto'>
														<Label>Employés</Label>
														<div className='ms-3'>
															{formState?.employees_ids?.map((item, idx) => (
																<div key={idx}>
																	{item?.name}
																</div>
															))}
														</div>
													</div>
													<div className='col-auto d-flex justify-content-end align-self-start'>
														<Button
															color={'primary'}
															//className='w-auto'
															size={'sm'}
															isLight
															onClick={() => {
																setEmployeePopup(true);
															}}>
															<Icon
																icon='add'
																size='2x'
																className=''></Icon>{' '}
															Ajouter Employé
														</Button>
													</div>
												</div>

												<FormGroup
													className='col-12'
													id='eventStart'
													label='Date de début'>
													<Input
														type={'datetime-local'}
														/* onChange={handleChange} */
														value={moment(
															formState?.start_datetime,
														).format(moment.HTML5_FMT.DATETIME_LOCAL)}
														name='start_datetime'
														autoComplete='off'
														onChange={handleChange}

													//disabled={true}
													/>
												</FormGroup>
												<FormGroup id='eventEnd' label='Date de fin'>
													<Input
														type={'datetime-local'}
														/* onChange={handleChange} */
														value={moment(
															formState?.end_datetime,
														).format(moment.HTML5_FMT.DATETIME_LOCAL)}
														name='end_datetime'
														onChange={handleChange}
														autoComplete='off'
													/>
												</FormGroup>
											</div>
										</CardBody>
									</Card>
								</div>
								<div className='col-xl-6 col-md-12'>
									<Card shadow='sm' stretch>
										<CardHeader>
											<CardLabel icon='InfoOutline' iconColor='primary'>
												<CardTitle>
													Information Client
												</CardTitle>
											</CardLabel>
										</CardHeader>
										<CardBody>
											<div className='row g-4'>
												<SearchBoxClient
													setSelectedClient={setSelectedClient}
													selectedClient={selectedClient}
													handelSelect={handelSelect}
												/>
												<FormGroup
													className='col-12'
													id='client'
													label={t('Remarques')}>
													<Textarea
														name='Remarque'
														placeholder={t('Remarques') + '...'}
														id='Remarque'
														value={formState?.Remarque}
														onChange={(e) => {
															handleChange(e);
															// e.target.style.height = 'auto';
															// e.target.style.height = `${e?.target?.scrollHeight}px`;
														}}
													/>
												</FormGroup>
												{formState?.type_int == 'Pose' && (
													<FormGroup className='col-12 py-2' id='amount'>
														<Label> Le client doit payer :</Label>
														<Input
															onChange={handleChange}
															//onBlur={handelBlurAmount}
															value={formState.amount_residual}
															name='amount_residual'
															type='number'
															placeholder='0.0'
														/>
													</FormGroup>
												)}

												<div className='row g-4 d-flex align-items-center'>
													<FormGroup
														className='col-12'
														id='client'
														label={'Fichiers'}>
														<Input
															type='file'
															multiple
															name='attachmentsCommercial'
															onChange={(e) => {
																const newAttachments = Array.from(
																	e?.target?.files,
																);
																setAttachmentsCommercial(
																	(prevAttachments) => [
																		...prevAttachments,
																		...newAttachments,
																	],
																);
															}}
														/>
													</FormGroup>
													<div className='row'>
														{!!attachmentsCommercial?.length &&
															attachmentsCommercial?.map(
																(file, index) => {
																	return (
																		<Attachements
																			key={index}
																			file={file}
																			index={index}
																			deleteImage={
																				deleteImage
																			}
																		/>
																	);
																},
															)}
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>
						</CardTabItem>
						<CardTabItem id='Devis' title='Devis'>
							<div className='d-flex justify-content-end '>
								{formState?.state != 'done' &&
									formState?.state != 'cancel' &&
									formState?.type_int != 'Pose' && (
										<Button
											className=''
											color='success'
											icon='PostAdd'
											onClick={() => setEditDevisModalStatus(true)}>
											Créer un devis
										</Button>
									)}
								{formState?.state != 'done' &&
									formState?.state != 'cancel' &&
									formState?.type_int == 'Pose' &&
									!!devisDatas?.length && (
										<Button
											className='w-auto ms-2 '
											color='warning'
											icon='PostAdd'
											onClick={() => setConfirmCreatePVPopup(true)}>
											Créer un PV de réception
										</Button>
									)}
								{/* <Button
									className='w-auto ms-2'
									color='primary'
									icon='PostAdd'
									hoverShadow='none'
									onClick={() => setConfirmDecharge(true)}>
									Créer une décharge
								</Button> */}
							</div>
							{userData?.role != 'Technicien' &&
								userData?.role?.toLocaleLowerCase() != 'achat' && (
									<div className='col-lg-6 mb-3' ref={ref}>
										<FormGroup className='autocomplete'>
											<Input
												placeholder='🔎︎ Rechercher par devis, client ...'
												onChange={handleDevisChange}
												onFocus={handleDevisChange}
												value={devisInputSearch}
												name='name'
												autoComplete='off'
											/>
											{openList && devisIsLoading ? (
												<div
													id='myInputautocomplete-list'
													className='autocomplete-items'>
													<div className='body'>
														<div tag={'button'}>
															<Spinner isSmall isGrow />{' '}
															<Spinner isSmall isGrow />{' '}
															<Spinner isSmall isGrow />
														</div>
													</div>
												</div>
											) : (
												!!filterDevisResult?.length && (
													<div
														id='myInputautocomplete-list'
														className='autocomplete-items'>
														<div className='body'>
															{filterDevisResult?.map((item, idx) => (
																<div
																	className='d-flex g-1'
																	key={idx}
																	onClick={() => {
																		handelSelectDevis(item);
																	}}>
																	<div className='w-auto'>
																		<small className={` px-2 `}>
																			<Icon
																				icon='Info'
																				className='me-1'
																				size={'lg'}
																			/>
																			<span className='text-dark'>
																				{item?.name}
																			</span>
																		</small>
																	</div>
																	<div className='w-auto'>
																		<small className={` px-2 `}>
																			<Icon
																				icon='Person'
																				className='me-1'
																				size={'lg'}
																			/>
																			<span className='text-dark'>
																				{item?.nom_client}
																			</span>
																		</small>
																	</div>
																	{(!!item?.phone_client ||
																		!!item?.mobile_client) && (
																			<div className='w-auto '>
																				<small
																					className={`  px-2   `}>
																					<Icon
																						icon='LocalPhone'
																						className='me-1'
																						size={'lg'}
																					/>
																					<span className='text-dark'>
																						{
																							item?.phone_client
																						}{' '}
																						{
																							item?.mobile_client
																						}
																					</span>
																				</small>
																			</div>
																		)}

																	<div className='w-auto '>
																		<small
																			className={`  px-2   `}>
																			<Icon
																				icon='Today'
																				className='me-1'
																				size={'lg'}
																			/>
																			<span className='text-dark'>
																				{moment(
																					item?.create_date,
																				).format(
																					'DD/MM/yyyy',
																				)}
																			</span>
																		</small>
																	</div>
																</div>
															))}
														</div>
													</div>
												)
											)}
										</FormGroup>
									</div>
								)}
							<div className='row mt-3'>
								{!!devisDatas?.length &&
									devisDatas?.map((devis, idx) => {
										return (
											<div className='col-lg-4 col-md-6 col-sm-12' key={idx}>
												<Card isCompact>
													<CardHeader>
														<CardLabel>
															<Label>Devis - {t(devis?.state)}</Label>
														</CardLabel>
														{userData?.role != 'Technicien' &&
															userData?.role?.toLocaleLowerCase() !=
															'achat' && (
																<CardActions>
																	<Icon
																		className='cursor-pointer'
																		icon='Delete'
																		color='danger'
																		size={'2x'}
																		onClick={() => {
																			console.log(
																				'delete last devis :',
																				formState?.devis_liste?.filter(
																					(item) =>
																						item !=
																						devis?.id,
																				),
																			);
																			setFormState({
																				...formState,
																				devis_liste:
																					formState?.devis_liste?.filter(
																						(item) =>
																							item !=
																							devis?.id,
																					),
																			});
																			setDevisDatas(
																				devisDatas?.filter(
																					(item) =>
																						item?.id !=
																						devis?.id,
																				),
																			);
																		}}></Icon>
																</CardActions>
															)}
													</CardHeader>
													<CardBody>
														<DevisCard
															card={devis}
															loadData={loadDevis}
														/>
													</CardBody>
												</Card>
											</div>
										);
									})}
							</div>
							{/* <div className='row mt-3'>
								{!!facturesDatas?.length &&
									facturesDatas?.map((facture) => {
										return (
											<div
												className='col-lg-4 col-md-6 col-sm-12'
												key={facture?.id}>
												<Card isCompact>
													<CardHeader>
														<CardLabel>
															<Label>
																Facture - {t(facture?.state)}
															</Label>
														</CardLabel>
													</CardHeader>
													<CardBody>
														<FactureCard
															card={facture}
															loadFactures={loadDevis}
														/>
													</CardBody>
												</Card>
											</div>
										);
									})}
							</div> */}
						</CardTabItem>
					</Card>
				</ModalBody>

				<ModalFooter className='px-4 pb-4'>
					<Button
						color='primary'
						className='w-100'
						type='submit'
						onClick={() => {
							handelSubmit();
						}}
						isDisable={isLoading}>
						{isLoading && <Spinner isSmall inButton />}
						{isLoading ? 'En cours' : t('Create')}
					</Button>
					<Button
						color='warning'
						className='w-100'
						onClick={() => setCreateModalStatus(false)}>
						{t('Cancel')}
					</Button>
				</ModalFooter>
				{!!obligation && (
					<StatusPopup
						icon='Cancel'
						color='danger'
						title={obligation}
						setOpen={setObligation}
					/>
				)}
				{!!error && <ErrorPopup error={error} setOpen={setError} />}
			</Modal>
			{searchModalStatus && (
				<SearchClient
					setCreateClientModalStatus={setSearchModalStatus}
					handelSelect={handelSelect}
					data={isFournisseur ? {} : selectedClient}
					// filterResult={filterResult || {}}
					// valueInput={formState}
					curentInput={curentInput}
					onChange={handleChange}
					newClient={isFournisseur}
				/>
			)}
			{editDevisModalStatus && (
				<DevisLinesContextProvider>
					<DevisPopUp
						card={newDevisData}
						setEditModalStatus={(param) => {
							setEditDevisModalStatus(param);
							//getInterventions();
							//setEditModalStatus(false);
						}}
						loadData={loadDevis}
					/>
				</DevisLinesContextProvider>
			)}
			{/* {searchModalStatus && (
				<SearchPopUp
					setSearchModalStatus={() => setSearchModalStatus(false)}
					filterResult={filterResult || {}}
					valueInput={formState}
					curentInput={curentInput}
					handelChange={handleChange}
					handelSelect={handelSelect}>
					{curentInput?.inputName == 'client' && (
						<Button
							color='primary'
							className='w-50 text-right'
							onClick={handelCreateClient}
							//isDisable={!formState?.project_title||!formState?.client}
						>
							+Créer Client
						</Button>
					)}
				</SearchPopUp>
			)} */}

			{/* {createClientModalStatus && (
				<CreateClientPopup
					setCreateClientModalStatus={() => setCreateClientModalStatus(false)}
					handelSelect={handelSelect}
				/>
			)} */}
			{!!posePopup && (
				<PosePopup
					setposePopup={setposePopup}
					setFormState={setFormState}
					formState={formState}
					setPose={setPose}
					pose={pose}
					setAttachmentsCommercial={setAttachmentsCommercial}
					handelSelect={handelSelect}
				/>
			)}
			{!!voiturePopup && (
				<VoiturePopup
					setVoiturePopup={setVoiturePopup}
					formState={formState}
					setFormState={setFormState}
				/>
			)}
			{!!parentIntervention && (
				<PopUpSeachParentIntervention
					parentInterventionsData={parentInterventionsData}
					setParentIntervention={setParentIntervention}
					handelSubmit={handelSubmit}
					formState={formState}
				/>
			)}
			{!!parentDevis && (
				<PopUpSeachDevisIntervention
					parentInterventionsData={parentInterventionsData}
					setParentDevis={setParentDevis}
					handelSubmit={handelSubmit}
					handleCreateDevis={handleCreateDevis}
					formState={formState}
				/>
			)}
			{!!employeePopup && (
				<EmployeePopup
					setOpenPopup={setEmployeePopup}
					data={formState?.employees_ids}
					handleUpdate={(list) => setFormState({ ...formState, employees_ids: list })}
				/>
			)}
		</>
	);
};

InterventionCreatePopUp.propTypes = {
	setCreateModalStatus: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	/* setNewPlanningData: PropTypes.func.isRequired, */
	newInterventionData: PropTypes.any.isRequired,
	getInterventions: PropTypes.func.isRequired,
};

export default InterventionCreatePopUp;
